import React, { useMemo } from "react"
import { graphql, PageProps } from "gatsby"
import { CoachingPageProps } from "../types/pages"
import PageLayout from "../layouts/page-layout"
import ServicePageLayout from "../layouts/service-page-layout"

const Coaching: React.FC<PageProps<CoachingPageProps>> = ({ data }) => {
  const { banner, coachingContent, coachingAcc } =
    data?.PAGE_CONTENT?.coachingOptions

  const accordions = useMemo(() => {
    return coachingAcc?.map(acc => {
      return {
        title: acc.title,
        description: acc.description,
      }
    })
  }, [JSON.stringify(coachingAcc)])

  return (
    <PageLayout seo={data?.PAGE_METADATA?.seo}>
      <ServicePageLayout
        banner={banner}
        pageTitle={data?.PAGE_METADATA?.title}
        content={coachingContent}
        accordions={accordions}
      />
    </PageLayout>
  )
}

export const query = graphql`
  query ($uri: String!, $locale: String!) {
    PAGE_METADATA: wpPage(
      uri: { eq: $uri }
      locale: { locale: { eq: $locale } }
    ) {
      id
      slug
      title
      link
      locale {
        locale
      }
      seo {
        metaDesc
        metaKeywords
        canonical
        opengraphDescription
        opengraphTitle
        opengraphType
        title
        twitterDescription
        twitterTitle
      }
    }
    PAGE_CONTENT: wpPage(
      uri: { eq: $uri }
      locale: { locale: { eq: $locale } }
    ) {
      coachingOptions {
        banner {
          altText
          sizes
          sourceUrl
          srcSet
        }
        coachingContent
        coachingAcc {
          description
          title
        }
      }
    }
  }
`

export default Coaching
